const API_URL = process.env.REACT_APP_API_URL;

function addBetaTester(data) {
    return fetch(API_URL + "registerTesters", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
}

const betaTesterApi = {
    addBetaTester,
}

export {betaTesterApi}
