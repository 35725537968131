import './App.css';
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import OurObjective from "./pages/OurObjective";
import OurTeam from "./pages/OurTeam";
import React from "react";
import OurSolution from "./pages/OurSolution";
import Partner from "./pages/Partner";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import BecomePartner from "./pages/BecomePartner";
import Timeline from "./pages/Timeline";

function App() {
  return (
    <div className="App">
      <header>
        <title>Angel</title>
      </header>
      <Navbar/>
        <BrowserRouter basename="/">
            <Routes>
                <Route exact path="/" element={<HomePage/>}/>
                <Route path="/mission" element={<OurObjective/>}/>
                <Route path="/team" element={<OurTeam/>}/>
                <Route path="/solution" element={<OurSolution/>}/>
                <Route path="/becomePartner" element={<BecomePartner/>}/>
                <Route path="/partner" element={<Partner/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/timeline" element={<Timeline/>}/>
            </Routes>
        </BrowserRouter>
        <Footer/>
    </div>
  );
}

export default App;
