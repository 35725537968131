import React, {useState, useEffect} from "react";
import "../styles/Navbar.css"
import { AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
    const [isPageSmall, setIsPageSmall] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const checkWindowWidth = () => {
        const windowWidth = window.innerWidth;
        const pageSmallThreshold = 1000; // Vous pouvez ajuster cette valeur selon vos besoins.

        if (windowWidth < pageSmallThreshold) {
            setIsPageSmall(true);
        } else {
            setIsPageSmall(false);
            setMenuVisible(false)
        }
    };

    function toogleMenu() {
        setMenuVisible(!menuVisible);
    }

    function MenuButtons({className}) {
        return (
            <div className={className}>
                <a href={"/"}>Accueil</a>
                <a href={"/solution"}>Notre Solution</a>
                <a href={"/mission"}>Notre Objectif</a>
                <a href={"/team"}>Notre Equipe</a>
                <a href={"/timeline"}>Chronologie</a>
                <a href={"/partner"}>Devenir Partenaire</a>
                <a href={"/contact"}>Contact</a>
            </div>
        )
    }

    useEffect(() => {
        window.addEventListener('resize', checkWindowWidth);
        checkWindowWidth();
        return () => {
            window.removeEventListener('resize', checkWindowWidth);
        };
    }, []);

    return (
        <>
            <div className="NavBar">
                <div className={"NavHorizontal"}>
                    <div className="NavHorizontal NavTitle">
                        <form action={"/"}>
                            <button  style={{border: "0px", backgroundColor: "transparent"}}>
                                <img className={"NavLogo"} src={require("../assets/angel_blue.png")} alt={"navLogo"}/>
                            </button>
                        </form>
                        <h1>Angel The Guardian</h1>
                    </div>
                    {isPageSmall ? (
                        <button className={"MenuButton"} onClick={toogleMenu}>
                            <p>Menu</p>
                            <AiOutlineMenu/>
                        </button>
                        ) : (
                        <MenuButtons className={"NavButtonBig"}/>
                        )}
                </div>
            </div>
            {menuVisible && (
                <div className={"NavBar navPlus"}>
                    <MenuButtons className={"NavButtonSmall"}/>
                </div>
                )}
        </>
    )
}

export default Navbar;