import React from "react";
import "../App.css"
import "../styles/HomePage.css"
import "../styles/OurSolution.css"
import TextAndImage from "../components/TextAndImage";


class OurSolution extends React.Component {
    render() {
        return (
            <div>
                <h1>Notre Solution</h1>
                <div className={"PageBloc"}>
                    <div className={"TextBloc"}>
                        <div className={"Horizontal"}>
                            <div className={"ProfileText SolutionText"}>
                                <h2>Produit</h2>
                                <p>Angel The Guardian est une application d'urgence révolutionnaire qui met le pouvoir de la
                                    sécurité entre les mains des utilisateurs. Notre solution se distingue par sa simplicité
                                    d'utilisation et son efficacité.</p>
                            </div>
                                <img src={require("../assets/idea.png")} alt={"Profile"} className={"PPImage"} style={{width: "200px"}}/>
                        </div>
                    </div>
                </div>
                <TextAndImage title={"Anges Gardiens"}
                              content={"Lorsqu'une personne se sent en danger ou est victime de harcèlement de rue, elle peut\n" +
                                  "activer l'application et envoyer sa localisation en temps réel aux utilisateurs proches qui ont\n" +
                                  "installé Angel the Guardian. Ces personnes, que nous appelons les \"anges gardiens\", sont\n" +
                                  "alors informées de la situation et peuvent intervenir pour aider la victime ou alerter les\n" +
                                  "autorités compétentes."}
                              image={require("../assets/alert.png")}/>

                <TextAndImage title={"Et plus encore .."}
                              content={"L'application offre également des fonctionnalités supplémentaires, telles que la possibilité de\n" +
                                  "signaler des incidents, de consulter une carte des lieux sûrs recommandés et d'accéder à\n" +
                                  "des ressources d'assistance et d'information. Nous mettons un point d'honneur à garantir la\n" +
                                  "confidentialité des utilisateurs et à respecter les normes de protection des données.\n" +
                                  "Notre solution repose sur l'idée de solidarité et de soutien mutuel. En connectant les\n" +
                                  "personnes dans le besoin à celles qui peuvent leur venir en aide, nous créons une\n" +
                                  "communauté bienveillante qui contribue à rendre les espaces publics plus sûrs pour tous."}
                              image={require("../assets/feature.png")}/>
            </div>
        )
    }
}

export default OurSolution;