import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Alert, styled } from '@mui/material';
import { emailVerification, phoneNumberVerification } from '../Utils';
import { partnerApi } from '../api/Partner';


const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        '&::after': {
            content: '"*"',
            color: 'red',
            marginLeft: 2,
            paddingRight: 4,
        }
    },
    '& .MuiInputLabel-asterisk': {
        display: 'none',
    }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#919fbd',
    color: 'white',
    '&:hover': {
        backgroundColor: '#717ea7'
    }
}));

const BecomePartner = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        location: "",
        webSite: "",
        message: "",
        password: "",
    });
    const [status, setStatus] = useState({ error: null, success: null });

    const handleChange = (prop) => (event) => {
        setFormData({ ...formData, [prop]: event.target.value });
    };

    const verifyInfo = () => {
        const { firstName, lastName, companyName, email, phoneNumber, location, message, password } = formData;
        if (!firstName || !lastName || !companyName || !email || !phoneNumber || !location || !message || !password) {
            return "Veuillez remplir tous les champs obligatoires.";
        }
        if (!emailVerification(email)) {
            return "Adresse e-mail incorrecte.";
        }
        if (!phoneNumberVerification(phoneNumber)) {
            return "Numéro de téléphone incorrect.";
        }
        return null;
    };

    const sendPartnerInfo = () => {
        const error = verifyInfo();
        if (error) {
            setStatus({ error });
            return;
        }
        const data = { ...formData };
        delete data.password;
        partnerApi.partnerRequest(data)
            .then((res) => {
                if (res.status === 200) {
                    setStatus({ error: null, success: "Votre demande de partenariat a bien été envoyée." });
                } else {
                    setStatus({ error: "Erreur lors de l'envoi de votre demande. Veuillez réessayer plus tard.", success: null });
                }
            })
            .catch(() => {
                setStatus({ error: "Erreur lors de l'envoi de votre demande. Veuillez réessayer plus tard.", success: null });
            });
    };

    return (
        <Box sx={{ p: 2, maxWidth: 500, mx: "auto" }}>
            <Typography variant="h4" gutterBottom>Devenir Partenaire</Typography>
            {status.success && <Alert severity="success">{status.success}</Alert>}
            {status.error && <Alert severity="error">{status.error}</Alert>}
            {['firstName', 'lastName', 'companyName', 'email', 'phoneNumber', 'location', 'password', 'message'].map((field) => (
                <StyledTextField
                    key={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    type={field === 'password' ? 'password' : field === 'email' ? 'email' : 'text'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData[field]}
                    onChange={handleChange(field)}
                    required
                />
            ))}
            <TextField label="Site web (facultatif)" variant="outlined" fullWidth margin="normal" value={formData.webSite} onChange={handleChange('webSite')} />
            <SubmitButton onClick={sendPartnerInfo}>Envoyer</SubmitButton>
        </Box>
    );
};

export default BecomePartner;
