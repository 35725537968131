import React from 'react';
import "../styles/Modal.css"

function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button onClick={onClose} className="close-button">
                    X
                </button>
                {children}
            </div>
        </div>
    );
}

export default Modal;