// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (max-width: 800px) {
    .Footer {
        flex-direction: column;
        align-items: center;
    }
    .Footer div {
        margin-bottom: 100px;
    }
}

.Footer a{
    font-size: 150%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 10px;
    text-decoration: underline;
}

.Footer a:hover {
    text-decoration: underline;
}

.Footer div {
    display: flex;
    flex: 1 1;
    }

.FooterRoute {
    flex-direction: column;
}

.FooterPrincipal {
    margin-top: -30px;
    flex-direction: row;
    /*align-self: center;*/
    justify-content: space-around;
}

.FooterPrincipal img {
    width: 100px;
    height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;IACvB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI,eAAe;IACf,+BAA+B;IAC/B,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,SAAO;IACP;;AAEJ;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".Footer {\n    color: white;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    background-color: black;\n    padding-top: 50px;\n    padding-bottom: 50px;\n}\n\n@media (max-width: 800px) {\n    .Footer {\n        flex-direction: column;\n        align-items: center;\n    }\n    .Footer div {\n        margin-bottom: 100px;\n    }\n}\n\n.Footer a{\n    font-size: 150%;\n    color: rgba(255, 255, 255, 0.8);\n    margin-bottom: 10px;\n    text-decoration: underline;\n}\n\n.Footer a:hover {\n    text-decoration: underline;\n}\n\n.Footer div {\n    display: flex;\n    flex: 1;\n    }\n\n.FooterRoute {\n    flex-direction: column;\n}\n\n.FooterPrincipal {\n    margin-top: -30px;\n    flex-direction: row;\n    /*align-self: center;*/\n    justify-content: space-around;\n}\n\n.FooterPrincipal img {\n    width: 100px;\n    height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
