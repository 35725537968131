import React from "react";
import "../App.css"
import "../styles/OurObjective.css"

class OurObjective extends React.Component{

    state = {
        margin: 20
    }

    handleResize() {
        const width = window.innerWidth;
        const margin = width/100 /3 * 4 +2;
        this.setState({margin: margin})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        return (
            <div>
                <h1>Notre Objectif</h1>
                <img src={require("../assets/angel_blue.png")} alt={"Logo Angel"} className={"MiddlePicture"}/>
                <div className={"PageBloc"}>
                    <div className={"Center"}>
                        <div className={"BigText"}>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                Notre objectif principal est de lutter contre le harcèlement de rue et d'offrir une assistance
                                rapide et efficace aux personnes qui en sont victimes. Nous croyons en un monde où
                                chacun peut se sentir en sécurité dans les espaces publics, et c'est dans cette optique que
                                nous avons créé Angel The Guardian.
                            </p>
                            <img src={require("../assets/electronique.jpg")} alt={"Electronic 3D"} className={"MiddlePicture"}/>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                Nous nous engageons à utiliser la technologie pour connecter les personnes dans le besoin
                                à des individus bienveillants et prêts à les aider. Notre application permet aux utilisateurs de
                                signaler une situation d'urgence et d'envoyer leur localisation aux personnes proches, qui
                                peuvent alors intervenir rapidement pour assister la victime.
                            </p>
                            <img src={require("../assets/gift.jpg")} alt={"Gift 3D"} className={"MiddlePicture"}/>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En offrant cette solution gratuite, nous voulons encourager la solidarité et la coopération
                                entre les individus. Nous croyons en la force de la communauté et en sa capacité à créer un
                                environnement sûr pour tous. Nous travaillons également en étroite collaboration avec des
                                partenaires et des établissements locaux pour fournir des refuges sûrs où les utilisateurs
                                peuvent se rendre en cas de besoin.
                            </p>
                            <img src={require("../assets/handShake.jpg")} alt={"HandShake 3D"} className={"MiddlePicture"}/>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                Notre objectif ultime est de réduire les incidents de harcèlement de rue, de donner aux
                                victimes le soutien dont elles ont besoin et de promouvoir un changement social durable
                                pour un avenir plus sûr.<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OurObjective;