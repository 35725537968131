import React from "react"

class TextAndImage extends React.Component<{
    title: string;
    content: string;
    image: any;
}>
{
    render () {
        return (
            <div className={"PageBloc"}>
                <div className={"TextBloc"}>
                    <div className={"Horizontal"}>
                        <div className={"ProfileText SolutionText"}>
                            <h2>{this.props.title}</h2>
                            <p>{this.props.content}</p>
                        </div>
                        <img src={this.props.image} alt={"Picture text"} className={"PPImage"}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextAndImage;