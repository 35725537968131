import React from "react";
import "../App.css"
import "../styles/Footer.css"

class Footer extends React.Component
{
    render () {
        return (
            <div className={"Footer"}>
                <div className={"FooterRoute"}>
                <a href={"/"}>Accueil</a>
                <a href={"/solution"}>Notre Solution</a>
                <a href={"/mission"}>Notre Objectif</a>
                <a href={"/team"}>Notre Equipe</a>
                <a href={"/partner"}>Devenir Partenaire</a>
                <a href={"/contact"}>Contact</a>
                </div>
                <div className={"FooterPrincipal"}>
                    <h1>Angel</h1>
                    <img src={require("../assets/angel_white.png")} alt={"Angel logo"} />
                </div>
                <div className={"FooterRoute"}>
                    ©2023 Angel. All right reserved.
                </div>
            </div>
        )
    }
}

export default Footer