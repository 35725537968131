function emailVerification(email) {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regexEmail.test(email);
}

function phoneNumberVerification(number) {
    const regexPhoneNumber = /^(?:\+\d{1,3}\s?)?(?:\d{1,4}[\s.-])?\d{8,15}$/;
    return (regexPhoneNumber.test(number));
}

export {emailVerification, phoneNumberVerification}