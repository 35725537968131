import React from "react"
import {Link} from "react-router-dom"
import "../App.css"
import "../styles/Partner.css"
import TextAndImage from "../components/TextAndImage";

class Partner extends React.Component {

    becomePartner() {
        this.props.navigation.navigate("/")
    }

    render() {
        return (
            <div>
                <h1>Qu'est-ce qu'un partenaire ?</h1>
                <TextAndImage title={""}
                              content={"Dans notre lutte contre l'insécurité et le harcèlement de rue, nous cherchons à collaborer\n" +
                                  "avec des partenaires locaux qui partagent notre vision d'un environnement sûr pour tous. Un\n" +
                                  "partenaire est un établissement qui accepte d'être affiché sur notre carte en tant que \"lieu\n" +
                                  "sûr\" où les utilisateurs d'Angel the Guardian peuvent se réfugier en cas de besoin."}
                              image={require("../assets/gps-map.jpg")} />
                <TextAndImage title={""}
                              content={"En devenant partenaire, vous vous engagez activement dans la lutte contre l'insécurité et\n" +
                                  "vous montrez votre soutien à notre cause. Votre établissement sera visible sur notre\n" +
                                  "application, ce qui permettra aux utilisateurs de trouver rapidement des endroits où se sentir\n" +
                                  "en sécurité."}
                              image={require("../assets/hand.jpg")}
                                />
                <TextAndImage image={require("../assets/increase.jpg")}
                              title={""}
                              content={"En tant que partenaire, vous jouez un rôle essentiel en offrant un refuge sûr et en\n" +
                                    "contribuant à créer une communauté solidaire. Vous aidez à renforcer le sentiment de\n" +
                                    "sécurité dans votre région et à promouvoir un changement social positif."}/>

                <div className={"PageBloc"}>
                    <div className={"Center"}>
                            <div className={"ProfileText"} style={{textAlign: "center", maxWidth: "80%"}}>
                                <h2></h2>
                                <p> En devenant partenaire, vous vous engagez activement dans la lutte contre l'insécurité et
                                    vous montrez votre soutien à notre cause. Votre établissement sera visible sur notre
                                    application, ce qui permettra aux utilisateurs de trouver rapidement des endroits où se sentir
                                    en sécurité.</p>
                            </div>
                        <Link to={"/becomePartner"}>
                            <button className={"AngelButton"} style={{maxHeight: "1%", marginBottom:"100px"}}>
                                <div style={{marginTop: "3%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "row"}}>
                                    <p>Devenir Partenaire</p>
                                    <img src={require("../assets/rigth_arrow.png")} alt={"next arrow"} style={{width: "50px", height: "50px", color: "white"}}/>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
                <img src={require("../assets/angel_blue.png")} alt={"Angel Logo"} className={"PPImage"} style={{width: "60%", alignSelf: "center", marginLeft: "0px"}}/>
            </div>
        )
    }
}

export default Partner