import React from "react";
import "../App.css"
import "../styles/Contact.css"

class Contact extends React.Component {

    state = {
        name: "",
        phoneNumber: "",
        email: "",
        message: "",
    }

    sendMessage() {
        console.log(this.state)
    }

    render() {
        return (
            <div style={{marginBottom: "100px"}}>
                <h1>Contactez-Nous !</h1>
                <img src={require("../assets/angel_blue.png")} alt={"Logo Anger"} style={{width: "50%", height: "auto", minWidth: "300px", maxWidth: "600px", marginBottom: "-150px"}}></img>
                <div className={"PageBloc"}>
                    <div className={"FollowInfo"}>
                        <h2>Suivez-nous</h2>
                        <div className={"FollowLogoBlock"}>
                            <form action={"https://www.instagram.com/angel.the_guardian/"} target={"_blank"}>
                                <button>
                                    <img src={require("../assets/instagram.png")} alt={"Inta logo"}/>
                                </button>
                            </form>
                            <form action={"https://www.linkedin.com/company/angel-the-guardian/"} target={"_blank"}>
                                <button>
                                    <img src={require("../assets/linkedIn.png")} alt={"LinkedIn logo"}/>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className={"ContactInfo"}>
                        <div className={"TakeContact"}>
                            <h2>Prendre contact</h2>
                            <input placeholder={"Nom complet"} onChange={(event) => {this.setState({name: event.target.value})}} type={"text"}/>
                            <input placeholder={"Numéro de téléphone"} onChange={(event) => {this.setState({phoneNumber: event.target.value})}} type={"tel"}/>
                            <input placeholder={"Email"} onChange={(event) => {this.setState({email: event.target.value})}} type={"email"}/>
                            <textarea placeholder={"Message"} onChange={(event) => {this.setState({message: event.target.value})}} />
                            <button className={"AngelButton"} onClick={this.sendMessage.bind(this)}>
                                Envoyer
                            </button>
                        </div>
                        <div className={"TalkToUs"}>
                            <h2>Parlez-nous</h2>
                            <h4>Email:</h4>
                            <p>theguardianangel42@gmail.com</p>
                            <h4>Numéro de téléphone:</h4>
                            <p>+32475731384</p>
                            <h4>Adresse:</h4>
                            <p>Av. de Tervueren 142/144, 1150 Bruxelles, Belgique</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact