const API_URL = process.env.REACT_APP_API_URL;

function partnerRequest(data) {
    console.log(process.env)
    console.log("API_URL", API_URL);
    return fetch(API_URL + "partner/request", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
}

const partnerApi = {
    partnerRequest,
}

export {partnerApi}
