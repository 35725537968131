import React from "react";
import "../App.css"
import "../styles/OurTeam.css"
import ProfileText from "../components/ProfileText";

class Timeline extends React.Component{
    state = {
        margin: 20
    }

    handleResize() {
        const width = window.innerWidth;
        const margin = width/100 /3 * 4 +2;
        this.setState({margin: margin})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        return (
            <div>
                <h1>Chonologie</h1>
                <div className={"PageBloc"}>
                    <div className={"Center"}>
                        <div className="introOutro">
                            <p>Suivez les différentes étapes passées et futures de Angel The Guardian</p>
                        </div>
                    </div>
                </div>
                <div className={"PageBloc"}>
                    <div className={"Center"}>
                        <div className={"BigText"}>
                            <h2><u>Septembre 2022 - Naissance d'une idée</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                Création du projet Angel the Guardian durant la picine d'innovation d'Epitech
                                "Moonshot".
                                Après des recherches approfondies et une réflexion intense,
                                nous avons eu l'inspiration de créer Angel the Guardian. Ce projet est né de notre
                                profonde
                                conviction qu'il est crucial de contribuer positivement à notre communauté. Nous avons
                                été animés
                                par l'excitation de donner vie à une idée concrète et réalisable, ayant un but noble et
                                tangible.
                                Angel the Guardian incarne notre engagement à utiliser la technologie pour apporter un
                                changement positif
                                dans le monde qui nous entoure.
                            </p>
                            <h2><u>Décembre 2022 - Preuve de concept</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                La POC, ou preuve de concept, est une démonstration initiale de faisabilité d'une idée
                                ou d'un concept.
                                Dans le contexte de notre application, la POC représente le premier pas concret vers la
                                concrétisation de notre vision.
                                C'est une version préliminaire de l'application qui met en lumière les fonctionnalités
                                clés et valide l'approche technique.
                                Grâce à cette POC, nous avons pu tester nos idées, recueillir des retours et évaluer la
                                viabilité de notre projet.
                            </p>
                            <h2><u>Avril 2023 - Fondation</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En avril 2023, nous avons posé les fondations de notre application.
                                Ce mois marque le début concret de notre voyage vers la création d'une expérience
                                exceptionnelle.
                                Avec détermination et engagement, nous avons commencé à ériger les bases sur lesquelles
                                reposera notre projet.
                                Ces fondations solides sont le socle sur lequel nous construirons chaque aspect de notre
                                application,
                                donnant vie à notre vision et préparant le terrain pour un avenir meilleur.
                            </p>
                            <h2><u>Juillet 2023 - Premiere fonctionnalité</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En juillet 2023, nous avons franchi une étape cruciale en ajoutant nos premières
                                fonctionnalités à notre projet.
                                Nous avons débuté en priorisant les éléments les plus essentiels, tels que le système de
                                connexion, les alertes,
                                les messages et la gestion des numéros favoris. Ces fonctionnalités initiales
                                constituent les bases de notre application,
                                offrant aux utilisateurs une expérience pratique et efficace dès le départ.
                            </p>
                            <h2><u>Janvier 2024 - Expansion et Amélioration</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En janvier 2024, nous avons enrichi notre application avec de nombreuses fonctionnalités
                                supplémentaires,
                                visant à offrir aux utilisateurs une expérience complète.
                                Nous avons également consacré des efforts considérables à améliorer chaque aspect de
                                l'application,
                                que ce soit en termes de convivialité, de performance ou de design.
                            </p>
                            <h2><u>Mai 2024 - BETA</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En mai 2024, nous avons créé la version bêta de notre projet. C'est un moment excitant
                                où nous invitons nos premiers utilisateurs à
                                découvrir notre application et à nous aider à perfectionner leur expérience. Cette
                                version bêta représente une étape importante dans
                                notre parcours vers le lancement officiel, et nous sommes impatients de recueillir les
                                commentaires et les suggestions de
                                nos utilisateurs pour améliorer encore notre produit.
                            </p>
                            <h2><u>Décembre 2024 - Lancement</u></h2>
                            <p style={{marginLeft: `${this.state.margin}%`, marginRight: `${this.state.margin}%`}}>
                                En décembre 2024, nous sommes enthousiastes à l'idée de vous annoncer le lancement
                                officiel de notre application sur les plateformes.
                                Ce n'est que le début de notre voyage ! Nous avons de grandes ambitions pour l'avenir de
                                notre application, et nous sommes déterminés à continuer à
                                innover et à améliorer notre produit pour répondre aux besoins changeants de nos
                                utilisateurs. Avec de nouvelles fonctionnalités passionnantes,
                                des mises à jour régulières et un engagement continu envers la qualité, nous sommes
                                impatients de voir notre communauté prospérer et de créer ensemble
                                un avenir numérique encore plus prometteur. Merci de faire partie de cette aventure avec
                                nous !
                            </p>
                            <img src={require("../assets/angel_blue.png")} alt={"Logo Angel"}
                                 className={"HomePageLogo"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Timeline
