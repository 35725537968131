import React from "react";
import "../App.css"
import "../styles/OurTeam.css"
import ProfileText from "../components/ProfileText";

class OurTeam extends React.Component{
    render() {
        return (
            <div>
                <h1>Notre Équipe</h1>
                <div className={"PageBloc"}>
                    <div className={"Center"}>
                        <div className="introOutro">
                            <p>Notre équipe est composée de professionnels passionnés par la sécurité et l'innovation
                            technologique. Chacun d'entre nous apporte une expertise spécifique pour faire avancer
                            notre mission avec dévouement et engagement.<br/>Voici les membres clés de notre équipe :</p>
                        </div>
                    </div>
                </div>
                <ProfileText
                    side={"left"}
                    image={require("../assets/Graham_PP.png")}
                    title={"Graham Santana - Chef de groupe:"}
                    content={"Avec une solide expérience en développement front, RGPD\n" +
                        "(Règlement général sur la protection des données), IoT (Internet des objets) et responsable\n" +
                        "du démarchage, Graham est le pilier de notre équipe. Il supervise les opérations\n" +
                        "quotidiennes, coordonne les efforts et garantit que notre application est à la pointe de la\n" +
                        "technologie et respecte les normes de confidentialité."} />
                <ProfileText
                    side={"right"}
                    image={require("../assets/Antoine_PP.jpeg")}
                    title={"Antoine VanGorp - Manager Back-End:"}
                    content={"Fort de son expertise en développement back-end, Antoine\n" +
                        "est responsable de la création et de la maintenance de l'infrastructure serveur de notre\n" +
                        "application. Il travaille en étroite collaboration avec l'équipe front-end pour garantir que les\n" +
                        "fonctionnalités de l'application sont fluides et réactives."} />
                <ProfileText
                    side={"left"}
                    image={require("../assets/Evan_PP.jpg")}
                    title={"Evan Mevel - Manager Front-end:"}
                    content={"Evan est notre expert en développement front-end. Il s'occupe\n" +
                        "de la conception et de l'implémentation de l'interface utilisateur de notre application, en\n" +
                        "veillant à ce qu'elle soit intuitive, esthétique et conviviale pour les utilisateurs."}/>
                <ProfileText
                    side={"right"}
                    image={require("../assets/Audrey_PP.png")}
                    title={"Audrey Amar - Scrum Master:"}
                    content={"En tant que Scrum Master, Audrey dirige notre équipe de manière agile et\n" +
                        "assure une communication transparente et efficace. Elle est également responsable de la\n" +
                        "direction artistique de notre application, en veillant à ce qu'elle offre une expérience visuelle\n" +
                        "attrayante et cohérente. Elle est responsable communication c'est-à-dire qu'elle apporte une\n" +
                        "attention particulière à l'image  d'Angel sur les réseaux ainsi qu’avec nos interlocuteurs. Enfin, elle\n" +
                        "participe au développement front-end."} />
                <ProfileText
                    side={"left"}
                    image={require("../assets/Loic_PP.jpg")}
                    title={"Loïc DeVille - Développeur back-end:"}
                    content={"Loïc se concentre sur le développement back-end, en mettant\n" +
                        "l'accent sur les aspects liés à l'IoT et au démarchage. Il travaille sur l'intégration de\n" +
                        "différentes fonctionnalités et sur l'optimisation des performances de notre application."}/>
                <ProfileText
                    side={"right"}
                    image={require("../assets/Theo_PP.png")}
                    title={"Theo Guguen - Développeur back-end:"}
                    content={"Theo complète notre équipe de développement back-end en\n" +
                        "se spécialisant dans les aspects liés au RGPD. Il s'assure que notre application est\n" +
                        "conforme aux réglementations en matière de protection des données et garantit la\n" +
                        "confidentialité des utilisateurs."} />
                <div className={"PageBloc"}>
                    <div className={"Center"}>
                        <div className="introOutro">
                            <p>Ensemble, nous formons une équipe solide et complémentaire, déterminée à fournir une
                                application d'urgence efficace pour lutter contre le harcèlement de rue et assurer la sécurité
                                des utilisateurs.</p>
                        </div>
                        <img src={require("../assets/angel_blue.png")} alt={"Logo Angel"} className={"HomePageLogo"}/>
                    </div>
                </div>

            </div>
        )
    }
}
export default OurTeam
