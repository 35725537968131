// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolutionText p {
    text-align: justify;
    max-width: 80%;
    /*width: 100%;*/
}

@media(max-width: 1200px) {
    .SolutionText p {
        max-width: none;
    }
}

.SolutionText {
    margin-right: 5%;
}`, "",{"version":3,"sources":["webpack://./src/styles/OurSolution.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".SolutionText p {\n    text-align: justify;\n    max-width: 80%;\n    /*width: 100%;*/\n}\n\n@media(max-width: 1200px) {\n    .SolutionText p {\n        max-width: none;\n    }\n}\n\n.SolutionText {\n    margin-right: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
