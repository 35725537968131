import React from "react";
import "../styles/OurTeam.css"


class ProfileText extends React.Component
<{
    side: string;
    image: string;
    height?: any;
    title: string;
    content: string;
}>
{

    state = {
        side: this.props.side
    }

    handleResize() {
        const width = window.innerWidth
        if (width <= 1200) {
            this.setState({side: "left"})
        }
        else if (this.state.side === "left" && this.props.side === "right") {
            this.setState({side: "right"})
        }
    }

    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        return (
            <div className={"PageBloc"}>
                <div className={"TextBloc"}>
                    <div className={"Horizontal"}>
                        {this.state.side === "right" ? (
                            <img src={this.props.image} alt={"Profile Picture"} className={"PPImage"}/>
                        ) : null}
                        <div className={this.state.side === "right" ? "ProfileText ProfileTextRight" : "ProfileText ProfileTextLeft"}>
                            <h2>{this.props.title}</h2>
                            <p>{this.props.content}</p>
                        </div>
                        {this.state.side === "left" ? (
                            <img src={this.props.image} alt={"Profile Picture"} className={"PPImage"}/>
                            ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileText