// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileText {
    /*padding-right: 5%;*/
}

.introOutro {
    padding-right: 5%;
    padding-left: 5%;
}

.introOutro p {
    text-align: justify;
    font-size: x-large;
    color: rgb(0, 0, 0);
}

.ProfileTextLeft {
    padding-right: 5%;
}

.ProfileTextRight {
    padding-left: 5%;
}

.ProfileText p, h2 {
    text-align: justify;
    font-size: x-large;
    color: rgba(0, 0, 0, 0.72);
}

@media(max-width: 800px) {
    .ProfileText p, h2 {
        font-size: large;

    }
}

.ProfileText h2 {
    text-decoration: underline;
}

.PPImage {
    width: 300px;
    height: auto;
    border-radius: 7%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/OurTeam.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI;QACI,gBAAgB;;IAEpB;AACJ;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".ProfileText {\n    /*padding-right: 5%;*/\n}\n\n.introOutro {\n    padding-right: 5%;\n    padding-left: 5%;\n}\n\n.introOutro p {\n    text-align: justify;\n    font-size: x-large;\n    color: rgb(0, 0, 0);\n}\n\n.ProfileTextLeft {\n    padding-right: 5%;\n}\n\n.ProfileTextRight {\n    padding-left: 5%;\n}\n\n.ProfileText p, h2 {\n    text-align: justify;\n    font-size: x-large;\n    color: rgba(0, 0, 0, 0.72);\n}\n\n@media(max-width: 800px) {\n    .ProfileText p, h2 {\n        font-size: large;\n\n    }\n}\n\n.ProfileText h2 {\n    text-decoration: underline;\n}\n\n.PPImage {\n    width: 300px;\n    height: auto;\n    border-radius: 7%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
