import React from "react";
import "../App.css"
import "../styles/HomePage.css"
import Modal from "../components/Modal";

import brussels_city_background from "../assets/brussels_city.jpeg"
import DownloadAppButton from "../components/DownloadAppButton";
import { betaTesterApi } from "../api/BetaTester";
import {emailVerification} from "../Utils";

class HomePage extends React.Component {

    state = {
        modalVisible: false,
        interestedUserEmail: "",
        interestedUserName: "",
        error: false,
        errorMessage: ""
    }

    sendMailInfo() {
        if (!emailVerification(this.state.interestedUserEmail)) {
            this.setState({errorMessage: "Adresse e-mail invalide.", error: true})
            return
        }
        const data = {
            fullName : this.state.interestedUserName,
            email: this.state.interestedUserEmail,
        }
        betaTesterApi.addBetaTester(data)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({modalVisible: false, error: false, errorMessage: ""})
                    console.log("Beta testeur correctement enregistré")
                }
                else if (res.status === 401) {
                    this.setState({error: true, errorMessage: "Cette adresse a déjà été enregistré."})
                }
                else {
                    this.setState({error: true, errorMessage: "Une erreur est survenue, veuillez réessayer plus tard."})
                    console.log("Error - sendMailInfo - code:", res.status)
                }
            })
            .catch((error) => {
                this.setState({error: true, errorMessage: "Une erreur est survenue, veuillez réessayer plus tard."})
                console.error(error)
            })
    }

    render() {
        return (
            <div>
                <div className="PageBloc">
                    <div className={"TextBloc"}>
                    <div className="Horizontal">
                        <div className={"Presentation"}>
                            <h1>Angel,<br/>un gardien<br/>au quotidien.</h1>
                            <p>La meilleure application contre le harcèlement de rue.<br/>
                                Téléchargez l'application Angel dès maintenant et incarnez le changement que vous voulez voir dans le monde.</p>
                           <DownloadAppButton onChange={() => {this.setState({modalVisible: true})}}/>
                        </div>
                        <img src={require("../assets/screen_home_page.png")} alt="Screen home page"
                             className={"HomePageImage"}/>
                    </div>
                    </div>
                </div>
                <div className={"PageBloc"}>
                        <div className="Teaser">
                            <h1>"C'est une révolution dans la lutte contre le harcèlement de rue."</h1>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <img src={require("../assets/angel_white.png")} alt={"logo Angel blanc"} style={{width: "100px", height: "auto"}}/>
                                <p>Angel the guardian</p>
                            </div>
                    </div>
                </div>
                <div className="PageBloc">
                    <div className={"IconBloc"}>
                        <div className="Horizontal">
                            <img src={require("../assets/screen_map_page.png")} alt={"Screen map page"} className={"HomePageImage"}/>
                            <div className={"DemoIcons"}>
                                <div className={"Horizontal HorizontalIcon"}>
                                    <div>
                                        <img src={require("../assets/signal.png")} alt={"Signal icon"}/>
                                        <h2>Système communautaire</h2>
                                        <p>Angel fonctionne grâce à une solide communauté qui se soutient mutuellement.</p>
                                    </div>
                                    <div>
                                        <img src={require("../assets/idea.png")} alt={"Idea Icon"}/>
                                        <h2>Simple d'utilisation</h2>
                                        <p>Ce système a été créé pour être le plus réactif et intuitif possible.</p>
                                    </div>
                                </div>
                                <div className={"Horizontal HorizontalIcon"}>
                                    <div>
                                        <img src={require("../assets/gift.png")} alt={"Signal icon"} />
                                        <h2>Totalement Gratuit</h2>
                                        <p>Application gratuite afin d'en faire profiter un maximum de personnes.</p>
                                    </div>
                                    <div>
                                        <img src={require("../assets/protect.png")} alt={"Idea Icon"}/>
                                        <h2>Sécurisé</h2>
                                        <p>Nous mettons un point d'honneur à garantir la confidentialité des utilisateurs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="PageBloc">
                    <div className="TextBloc">
                        <div className="Horizontal">
                            <div className={"Presentation"}>
                                <h1>Devenez un Guardian.</h1>
                                <p>Rejoignez la mission Angel en devenant un Guardian.<br/>
                                    Venez-en aide à la communauté en devenant un gardien. Et faites la différence chaque jour.</p>
                            </div>
                            <img src={require("../assets/angel_blue.png")} alt="Screen home page"
                                 className={"HomePageLogo"}/>
                        </div>
                    </div>
                </div>
                <div className="PageBloc">
                    <div className="Center" style={{backgroundImage: `url(${brussels_city_background})`, // Utilisez la variable de votre image importée
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '70vh',
                    }}>
                            <h1 style={{color: "white", fontSize: "400%"}}>Arrêtez d'attendre.<br/>Agissez.</h1>
                    </div>
                </div>
                <div className={"PageBloc"}>
                    <div className={"Teaser"} style={{marginTop: "-100px"}}>
                        <h1>Téléchargez l'application dès maintenant !</h1>
                        <DownloadAppButton onChange={() => {this.setState({modalVisible: true})}}/>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <img src={require("../assets/angel_white.png")} alt={"logo Angel blanc"} style={{width: "100px", height: "auto"}}/>
                            <p>Angel the guardian</p>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modalVisible} onClose={() => {this.setState({modalVisible: false, error: false, errorMessage: ""})}}>
                    <p>DEVERNEZ BETA TESTEUR AUJOURD'HUI !!<br/>Laisser votre adresse email afin d'être parmi les premiers au courant des avancements.</p>
                    <div className={"BetaTesterInfo"}>
                        <input onChange={(event) => {this.setState({interestedUserName :event.target.value})}} placeholder={"Nom Complet"} type={"text"}/>
                        <input onChange={(event) => {this.setState({interestedUserEmail : event.target.value})}} placeholder={"Email"} type={"email"}/>
                        {this.state.error !== false && (<p className={"ErrorMessage"}>{this.state.errorMessage}</p>)}
                        <button className={"AngelButton"} style={{backgroundColor: "#BFD2C1"}} onClick={this.sendMailInfo.bind(this)}>Envoyer</button>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default HomePage;