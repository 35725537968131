// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BigText {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 200px;
}

.BigText p {
    text-align: justify;
    font-size: x-large;
    margin-bottom: 150px;
    /*margin-right: 20%;*/
    /*margin-left: 20%;*/
}

.MiddlePicture {
    width: 600px;
    height: auto;
    border-radius: 7%;
    margin-bottom: 10px;
}

@media(max-width: 700px) {
    .MiddlePicture {
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/OurObjective.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".BigText {\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    flex-wrap: wrap;\n    margin-bottom: 200px;\n}\n\n.BigText p {\n    text-align: justify;\n    font-size: x-large;\n    margin-bottom: 150px;\n    /*margin-right: 20%;*/\n    /*margin-left: 20%;*/\n}\n\n.MiddlePicture {\n    width: 600px;\n    height: auto;\n    border-radius: 7%;\n    margin-bottom: 10px;\n}\n\n@media(max-width: 700px) {\n    .MiddlePicture {\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
