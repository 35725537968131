import React from "react"
import Applebutton from "../assets/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg";
import "../styles/HomePage.css"

class DownloadAppButton extends React.Component
<{
    onChange: any;
}>
{
    render() {
        return (
            <div className={"downloadButton"}>
                <button onClick={this.props.onChange}>
                    <img src={require("../assets/google-play-badge.png")}
                         alt="download on Google Play Store"/>
                </button>
                <button onClick={this.props.onChange}>
                    <img src={Applebutton} alt="download on Apple Store"/>
                </button>
            </div>
        )
    }
}

export default DownloadAppButton;